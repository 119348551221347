<template>
	<div id="app">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" />
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive" />
	</div>
</template>
<script>
export default {
	data() {
		return {};
	},
	created() {
		if (localStorage.asyncRouter) {
			this.$store.commit(
				'asyncRouter',
				JSON.parse(localStorage.asyncRouter)
			);
		} else {
			const RouteView = {
				name: 'RouteView',
				render: (h) => h('router-view'),
			};
			let routerinfo = [
				{
					path: '/',
					name: 'home',
					component: 'views/home/home',
					redirect: '/index',
					children: [
						{
							path: '/index',
							name: 'index',
							component: RouteView,
							redirect: '/index/info',
							meta: { name: '首页' },
							children: [
								{
									path: '/index/info',
									name: 'indexInfo',
									component: 'views/index/index',
									meta: {
										name: '首页',
										icon: 'icon-shouye',
										isShow: true,
									},
								},
								{
									path: '/index/mycustomer',
									name: 'myCustomer',
									component: 'views/mycustomer/mycustomer',
									meta: {
										name: '悦融易推荐给我的客户',
										icon: 'icon-kehuguanli',
										isShow: true,
									},
								},
								{
									path: '/index/companycustomer',
									name: 'companyCustomer',
									component:
										'views/companycustomer/companycustomer',
									meta: {
										name: '我推荐给悦融易的客户',
										icon: 'icon-kehu',
										isShow: true,
									},
								},
								{
									path: '/index/inquiry',
									name: 'inquiry',
									component: 'views/inquiry/inquiry',
									meta: {
										name: '悦融易客户询单',
										icon: 'icon-xundan',
										isShow: true,
									},
								},
								{
									path: '/index/scheme',
									name: 'scheme',
									component: 'views/scheme/scheme',
									meta: {
										name: '客户的方案',
										icon: 'icon--fanganguanli',
										isShow: true,
									},
								},
								{
									path: '/index/approval',
									name: 'approval',
									component: 'views/approval/approval',
									meta: {
										name: '客户的批复',
										icon: 'icon-pifu',
										isShow: true,
									},
								},
								{
									path: '/index/dataanalysis',
									name: 'dataanalysis',
									component:
										'views/dataanalysis/dataanalysis',
									meta: {
										name: '银行流水分析',
										icon: 'icon-yinhangliushui',
										isShow: true,
									},
								},
								{
									path: '/index/financial',
									name: 'financial',
									component: 'views/financial/financial',
									meta: {
										name: '财务数据分析',
										icon: 'icon-ziyuanx',
										isShow: true,
									},
								},
								{
									path: '/index/myproduct',
									name: 'myproduct',
									component: 'views/myproduct/myproduct',
									meta: {
										name: '我机构产品',
										icon: 'icon-productManagement',
										isShow: true,
									},
								},

								{
									path: '/index/branch',
									name: 'branch',
									component: 'views/branch/branch',
									meta: {
										name: '我的网点',
										icon: 'icon-wangdian',
										isShow: true,
									},
								},
								{
									path: '/index/myinfo',
									name: 'userinfoInfo',
									component: 'views/userinfo/userinfo',
									meta: {
										name: '我的信息',
										icon: 'icon-wodexinxi_jibenxinxi',
										isShow: true,
									},
								},
								{
									path: '/index/notice',
									name: 'notice',
									component: 'views/notice/notice',
									meta: {
										name: '合作须知',
										icon: 'icon-lvshigaozhihan',
										isShow: true,
									},
								},
								{
									path: '/index/changepassword',
									name: 'changepassword',
									component:
										'views/changepassword/changepassword',
									meta: {
										name: '修改密码',
										icon: 'icon-iconfont11',
										isShow: false,
									},
								},
								{
									path: '/index/notification',
									name: 'notification',
									component:
										'views/notification/notification',
									meta: {
										name: '通知管理',
										isShow: false,
									},
								},
							],
						},
						// {
						// 	path: '/userinfo',
						// 	name: 'userinfo',
						// 	component: RouteView,
						// 	redirect: '/userinfo/info',
						// 	meta: {
						// 		name: '个人中心',
						// 	},
						// 	children: [

						// 	],
						// },
						// {
						// 	path: '/orderlist',
						// 	name: 'orderlist',
						// 	component: RouteView,
						// 	redirect: '/orderlist/info',
						// 	meta: {
						// 		name: '订单列表',
						// 	},
						// 	children: [
						// 		{
						// 			path: '/orderlist/info',
						// 			name: 'orderlistInfo',
						// 			component: 'views/orderlist/orderlist',
						// 			meta: { name: '订单列表详情' },
						// 		},
						// 	],
						// },
					],
				},
			];
			this.$store.commit('asyncRouter', routerinfo);
			localStorage.setItem('asyncRouter', JSON.stringify(routerinfo));
		}
	},
	methods: {},
};
</script>
<style lang="scss"></style>
