import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
Vue.use(VueRouter)
export const defaultRoutes = [{
  path: '/login',
  name: 'login',
  component: () => import('@/views/login/login'),
}, {
  path: '/register',
  name: 'register',
  component: () => import('@/views/register/register'),
}, {
  path: '/policy',
  name: 'policy',
  component: () => import('@/views/policy/policy'),
}, {
  path: '/public',
  name: 'public',
  component: () => import('@/views/public/public'),
}, {
  path: '/transferForm',
  name: 'transferForm',
  component: () => import('@/components/transfer/transferForm'),
}, {
  path: '/clientInfo',
  name: 'clientInfo',
  component: () => import('@/views/clientInfo/clientInfo'),
  meta: {
    keepAlive: false
  }
},
{
  path: '/mobileproduct',
  name: 'mobileproduct',
  component: () => import('@/views/mobileproduct/mobileproduct'),
  meta: {
    keepAlive: false
  }
},
{
  path: '/WeChatInfo',
  name: 'WeChatInfo',
  component: () => import('@/views/WeChatInfo/WeChatInfo'),
  meta: {
    keepAlive: false
  }
}, {
  path: '/tokenlogin',
  name: 'tokenlogin',
  component: () => import('@/views/tokenlogin/tokenlogin'),
}, {
  path: '/clientInquiry',
  name: 'currentinquiry',
  component: () => import('@/views/currentinquiry/currentinquiry'),
}, {
  path: '/forgetpassword',
  name: 'forgetpassword',
  component: () => import('@/views/forgetpassword/forgetpassword'),
}, {
  path: '/mobileReply',
  name: 'mobileReply',
  component: () => import('@/views/mobileReply/mobileReply'),
  meta: {
    title: '悦融易聊天记录'
  }
},];
export const my404 = [{
  path: '*',
  name: 'my404',
  component: () => import('@/views/404/404'),
}];
const originalreplace = VueRouter.prototype.replace;
//修改原型对象中的replace方法
VueRouter.prototype.replace = function replace(location) {
  return originalreplace.call(this, location).catch((err) => err);
};

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes: [
    ...defaultRoutes
  ]
})




//路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title == '悦融易聊天记录') {
    document.title = to.meta.title
  } else {
    document.title = '悦融易融资超市（资金端）'

  }
  const userInfo = localStorage.getItem('userInfo') || '';
  const token = userInfo ? JSON.parse(userInfo).token : '';

  //to 要去的路由配置
  //from 当前的路由配置
  //next 一定要调用，让to的路由配置继续生效，比如如果去登陆直接next(),否则判断token是否存在，如果存在就next()
  NProgress.start()
  if ((to.path === '/login' || to.path === '/register') && token) {
    NProgress.done()

    return next('/index');
  }//使用return，不需要写else

  if (to.path === '/login' || to.path === '/mobileReply' || to.path === '/register' || to.path === '/tokenlogin' || to.path === '/policy' || to.path === '/clientInquiry' || to.path === '/mobileproduct' || to.path === '/forgetpassword' || to.path === '/WeChatInfo' || to.path === '/clientInfo') return next();//使用return，不需要写else

  //判断其他页面是否有token
  NProgress.start()

  //存在继续往后走
  if (token) return next();

  localStorage.clear()
  // this.$router.push({name:'login'}) #没有this,无法使用
  router.push('/login')


});

router.afterEach((to, from) => {
  NProgress.done()
})
export default router
