/*
包含n个接口请求函数的模块
函数的返回值: promise对象
 */
import { get, post, put } from './ajax'
import qs from 'qs';


export const checklogin = parameters => post('/api/user/checklogin.do', qs.stringify(parameters)) // 密码登录接口
export const sendSMSCode = parameters => post('/api/user/sendSMSCode.do', qs.stringify(parameters)) // 验证码接口
export const checkSMSCode = parameters => post('/api/user/checkSMSCode.do', qs.stringify(parameters)) // 验证码登录接口
export const itemByType = parameters => get('/api/dictionary/itemByType.do', parameters) // 公共字典接口
export const userRegister = parameters => post('/api/user/userRegister.do', qs.stringify(parameters)) // 注册接口

export const roleList = parameters => post('/api/role/roleList.do', qs.stringify(parameters)) // 用户权限菜单
export const modifyPassword = parameters => post('/api/user/modifyPassword.do', qs.stringify(parameters)) // 修改密码

export const networkList = parameters => post('/api/cooperationNetwork/networkList.do', qs.stringify(parameters)) // 所属网点 
export const financialOrganizetionList = parameters => post('/api/financialOrganizetion/financialOrganizetionList.do', qs.stringify(parameters)) // 金融机构 

export const updateCooperationPresident = parameters => post('/api/cooperationPresident/updateCooperationPresident.do', qs.stringify(parameters)) // 保存我的个人信息 
export const readCooperationPresident = parameters => post('/api/cooperationPresident/readCooperationPresident.do', qs.stringify(parameters)) // 查询我的个人信息 
export const networkAreaList = () => post('/api/cooperationNetwork/networkAreaList.do') //  
export const networkManagerList = parameters => post('/api/cooperationNetwork/networkManagerList.do', qs.stringify(parameters)) // 选择网点经理接口 
export const readCooperationNetwork = parameters => post('/api/cooperationNetwork/readCooperationNetwork.do', qs.stringify(parameters)) // 我的网点信息 
export const updateCooperationNetwork = parameters => post('/api/cooperationNetwork/updateCooperationNetwork.do', qs.stringify(parameters)) // 保存我的网点信息 
export const clientListOfPresident = parameters => post('/api/client/clientListOfPresident.do', qs.stringify(parameters)) // 我的客户 
export const getClientInfoSensitive = parameters => post('/api/client/getClientInfoSensitive.do', qs.stringify(parameters)) //  是否脱敏
export const getClientInfo = parameters => post('/api/client/getClientInfo.do', qs.stringify(parameters)) //  客户信息列表详情
export const getSensitiveClientInfo = parameters => post('/api/client/getSensitiveClientInfo.do', qs.stringify(parameters)) //  客户信息列表详情
export const getSensitiveClientInfoForInquiry = parameters => post('/api/client/getSensitiveClientInfoForInquiry.do', qs.stringify(parameters)) //  客户信息列表详情
export const cooperationFeedbackSave = parameters => post('/api/client/cooperationFeedbackSave.do', qs.stringify(parameters)) //  反馈我的合作意愿及意见
export const readCooperationFeedback = parameters => post('/api/client/readCooperationFeedback.do', qs.stringify(parameters)) //  反馈我的合作意愿及意见
export const followFeedbackList = parameters => post('/api/followFeedback/followFeedbackList.do', qs.stringify(parameters)) //  信息列表
export const addFollowFeedback = parameters => post('/api/followFeedback/addFollowFeedback.do', qs.stringify(parameters)) //  发送信息
// 我推荐给悦融易的客户
export const recommendClientList = parameters => post('/api/client/recommendClientList.do', qs.stringify(parameters))
export const recommendClientSave = parameters => post('/api/client/recommendClientSave.do', qs.stringify(parameters))
// 悦融易客户询单
export const clientInquiryList = parameters => post('/api/clientInquiry/list.do', qs.stringify(parameters))
export const clientInquiryReply = parameters => post('/api/clientInquiry/reply.do', qs.stringify(parameters))
// 客户的方案
export const schemeInfoList = parameters => post('/api/schemeInfo/schemeInfoList.do', qs.stringify(parameters))
// 客户的批复
export const schemeApprovalInfoList = parameters => post('/api/schemeApprovalInfo/schemeApprovalInfoList.do', qs.stringify(parameters))
// 金融产品
export const financialProductList = parameters => post('/api/financialProduct/financialProductList.do', qs.stringify(parameters)) //查询金融产品列表
export const addFinancialProduct = parameters => post('/api/financialProduct/addFinancialProduct.do', qs.stringify(parameters, { indices: false })) //新增金融产品列表
export const readFinancialProduct = parameters => post('/api/financialProduct/readFinancialProduct.do', qs.stringify(parameters)) //金融产品详情
export const updateFinancialProduct = parameters => post('/api/financialProduct/updateFinancialProduct.do', qs.stringify(parameters, { indices: false })) //修改金融产品详情
// 消息列表
export const systemMessageList = parameters => post('/api/systemMessage/systemMessageList.do', qs.stringify(parameters))
export const readSystemMessage = parameters => post('/api/systemMessage/readSystemMessage.do', qs.stringify(parameters))
export const getSystemMessageNum = () => post('/api/systemMessage/getSystemMessageNum.do')

// url 登录
export const shortLinkLogin = parameters => post('/api/user/shortLinkLogin.do', qs.stringify(parameters))

export const getUserInfoByToken = parameters => post('/api/user/getUserInfoByToken.do', qs.stringify(parameters))


export const importBankFlow = parameters => post('/api/fm/importBankFlow.do', parameters)
export const bankFlowList = parameters => post('/api/fm/bankFlowList.do', qs.stringify(parameters))
export const calculateBankStatement = parameters => post('/api/fm/calculateBankStatement.do', qs.stringify(parameters, { indices: false })) //  自动计算
export const clearBankFlow = parameters => post('/api/fm/clearBankFlow.do', qs.stringify(parameters)) //  清空银行流水记录

export const deleteRecord = parameters => post('/api/fm/deleteRecord.do', qs.stringify(parameters)) //  删除
export const flowInClientList = parameters => post('/api/fm/flowInClientList.do', qs.stringify(parameters)) //  前10大流入客户查询
export const flowOutClientList = parameters => post('/api/fm/flowOutClientList.do', qs.stringify(parameters)) //  前10大流出客户查询
export const flowInAmountList = parameters => post('/api/fm/flowInAmountList.do', qs.stringify(parameters)) //  10笔大额流入查询
export const flowOutAmountList = parameters => post('/api/fm/flowOutAmountList.do', qs.stringify(parameters)) //  10笔大额流出查询
export const downloadBankFlowTemplate = parameters => get('/api/fm/downloadBankFlowTemplate.do', parameters) //  下载
export const forgetPassword = parameters => post('/api/user/forgetPassword.do', qs.stringify(parameters)) //  修改密码

export const getUserNameList = parameters => post('/api/fm/getUserNameList.do', qs.stringify(parameters)) // 客户名称列表

export const resubmitRegisterInfo = parameters => post('/api/cooperationPresident/resubmitRegisterInfo.do', qs.stringify(parameters))
export const changeProtocolIdentification = () => post('/api/user/changeProtocolIdentification.do')
export const fmFinanceSubjectAdd = parameters => post('/api/fmFinanceSubject/add.do', qs.stringify(parameters))
export const fmFinanceSubjectList = parameters => post('/api/fmFinanceSubject/list.do', qs.stringify(parameters))
export const financeSubjectTemplate = parameters => get('/api/financeSubjectTemplate/list.do', parameters)

export const inputNew = parameters => post('/api/fmFinanceSubject/inputNew.do', parameters)
export const fmFinanceSubjectgetUserNameList = parameters => get('/api/fmFinanceSubject/getUserNameList.do', parameters)
export const calculateFinanceQuota = parameters => post('/api/fmFinanceSubject/calculateFinanceQuota.do', qs.stringify(parameters))
export const fmFinanceQuota = parameters => post('/api/fmFinanceQuota/list.do', qs.stringify(parameters))
export const fmFinanceQuotaAdd = parameters => post('/api/fmFinanceQuota/add.do', qs.stringify(parameters))

export const fmFinanceSubjectRead = parameters => get('/api/fmFinanceSubject/read.do', parameters)
export const fmFinanceSubjectDelete = parameters => get('/api/fmFinanceSubject/delete.do', parameters)
export const fmFinanceSubjectUpdate = parameters => post('/api/fmFinanceSubject/update.do', qs.stringify(parameters))

export const fmFinanceQuotaRead = parameters => get('/api/fmFinanceQuota/read.do', parameters)
export const fmFinanceQuotaDelete = parameters => get('/api/fmFinanceQuota/delete.do', parameters)
export const fmFinanceQuotaUpdate = parameters => post('/api/fmFinanceQuota/update.do', qs.stringify(parameters))
export const clear = parameters => get('/api/fmFinanceSubject/clear.do', parameters)
export const updateSchemeInfo = parameters => post('/api/schemeInfo/updateSchemeInfo.do', qs.stringify(parameters))
export const updateSchemeApprovalInfo = parameters => post('/api/schemeApprovalInfo/updateSchemeApprovalInfo.do', qs.stringify(parameters))
export const schemeLinkChange = parameters => post('/api/schemeInfo/schemeLinkChange.do', qs.stringify(parameters))
export const schemeApprovalLinkChange = parameters => post('/api/schemeApprovalInfo/schemeApprovalLinkChange.do', qs.stringify(parameters))
export const readSchemeApprovalInfo = parameters => post('/api/schemeApprovalInfo/readSchemeApprovalInfo.do', qs.stringify(parameters))
export const readClientInquiry = parameters => post('/api/clientInquiry/readClientInquiry.do', qs.stringify(parameters))
export const weChatQueryfollowFeedbackList = parameters => post('/api/followFeedback/weChatQueryfollowFeedbackList.do', qs.stringify(parameters))
export const weChatAddFollowFeedback = parameters => post('/api/followFeedback/weChatAddFollowFeedback.do', qs.stringify(parameters))
export const clientBankFlowList = parameters => get('/api/clientBankFlow/clientBankFlowList.do?clientId=' + parameters.clientId) // 公共字典接口


export const getNotRecommendPresidentList4Requirement = parameters => get('/api/fpmCooperationPresident/getNotRecommendPresidentList4Requirement.do', parameters)
export const requirementMappingPresident = parameters => post('/api/clientPresidentRelated/requirementMappingPresident.do', qs.stringify(parameters))
export const publicFieldPermissionVerification = parameters => get('/api/dictionary/publicFieldPermissionVerification.do', parameters)
export const readUserInfoOfClient = parameters => post('/api/user/readUserInfoOfClient.do', qs.stringify(parameters))
export const translateTheNanagerRecords = parameters => post('/api/clientPresidentRelated/translateTheNanagerRecords.do', qs.stringify(parameters))
export const getVfqClinetData = parameters => post('/api/clientKeyInformation/getVfqClinetData.do', qs.stringify(parameters))
export const getVfqClinetDatas = parameters => post('/api/client/getVfqClinetData.do?companyCode=' + parameters.companyCode) // 登录接口



export const getClientInfoForApplet = parameters => post('/api/client/getClientInfoForApplet.do', qs.stringify(parameters))
export const authorizedAddress = parameters => post('/api/client/generateH5Url.do', qs.stringify(parameters))

export const clientFinanceQuota = parameters => post('/api/clientFinanceQuota/shortList.do', qs.stringify(parameters))
export const clientFinanceProfitSheet = parameters => post('/api/clientFinanceProfitSheet/shortList.do', qs.stringify(parameters))
export const clientFinanceBalanceSheet = parameters => post('/api/clientFinanceBalanceSheet/shortList.do', qs.stringify(parameters))
