// router/getRoutes.js
import router from "./index";
// 导入默认的配置的静态路由
import { defaultRoutes, my404 } from "./index"
const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
};
export const getRoutes = arr => {
  return arr.map(({ path, name, component, meta, children }) => {
    const route = {
      path,
      name,
      meta: {
        ...meta,
        require: true
      },
      component: component.name ? RouteView : () => import("@/" + component + ".vue")
    };
    if (children) {
      route.redirect = children[0].path;
      route.children = getRoutes(children);
    }
    return route;
  });
};

export const setRoutes = list => {
  const routeList = getRoutes(list);
  const allroute = routeList.concat(my404)
  router.addRoutes(allroute);
  // 路由 options 并不会随着 addRoutes 动态响应，所以要在这里进行设置
  router.options.routes = defaultRoutes.concat(allroute);
};
