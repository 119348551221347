import axios from 'axios'; // 引入axios
import { Message, Loading } from 'element-ui';
import router from "../router/index";
let loadingInstance = null


const clearTimeoutByUrl = (requestList) => {
  for (let item in requestList) {
    clearTimeout(requestList[item]['timeId']);
  }
}
const service = {
  _requestCount: 0,// 累加请求次数
  _requestTimeIdList: []
};
function startLoading () {

  service._requestCount++;
  // 如果接口请求小于200ms的话 那么就不显示loading
  const timeId = setTimeout(() => {
    loadingInstance = Loading.service({
      lock: true,
      background: 'rgba(255, 255, 255, 0.6)',
      fullscreen: true
    })
  }, 350);
  service._requestTimeIdList.push({
    timeId
  });

}
function endLoading () {
  service._requestCount--;
  // clear 200ms 后准备展示的loading
  clearTimeoutByUrl(service._requestTimeIdList);
  if (service._requestCount <= 0) {
    loadingInstance && loadingInstance.close()

  }

}

// 环境的切换
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
} else if (process.env.NODE_ENV === 'debug') {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
}
axios.defaults.timeout = 100000
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data'

// 请求拦截器
axios.interceptors.request.use(
  config => {
    startLoading()
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const setUserInfo = localStorage.getItem('userInfo') || '';
    config.headers.token = setUserInfo ? JSON.parse(setUserInfo).token : ''

    return config
  },
  error => {
    endLoading()
    return Promise.error(error)
  })

// 响应拦截器
axios.interceptors.response.use(
  response => {
    endLoading()
    if (response.data && response.data.returncode == 1009) {
      Message({
        showClose: true,
        message: '用户过期，请重新登录',
        type: 'error'
      });
      localStorage.clear()
      router.replace('/login')
      return Promise.resolve(response)

    } else if (response.data && response.data.returncode == 1) {
      if (response.config.url != '/api/client/cooperationFeedbackSave.do' && response.config.url != '/api/fmFinanceSubject/inputNew.do' && response.config.url != '/api/clientPresidentRelated/requirementMappingPresident.do') {
        Message({
          showClose: true,
          message: response.data.message,
          type: 'error'
        });
      }

      return Promise.resolve(response)

    } else {
      return Promise.resolve(response)

    }
  },
  // 服务器状态码不是200的情况
  error => {
    endLoading()

    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          localStorage.clear()
          router.replace({
            path: '/login'
          })
          break
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        // case 403:
        //   Toast({
        //     message: '登录过期，请重新登录',
        //     duration: 1000,
        //     forbidClick: true
        //   })
        //   // 清除token
        //   localStorage.removeItem('token')
        //   store.commit('loginSuccess', null)
        //   // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
        //   setTimeout(() => {
        //     router.replace({
        //       path: '/',
        //       query: {
        //         redirect: router.currentRoute.fullPath
        //       }
        //     })
        //   }, 1000)
        //   break
        // 404请求不存在
        case 404:
          // Toast({
          //   message: '网络请求不存在',
          //   duration: 1500,
          //   forbidClick: true
          // })
          break
        // 其他错误，直接抛出错误提示
        default:
        // Toast({
        //   message: "服务器错误 ！！！",
        //   // message: error.response.data.message,
        //   duration: 1500,
        //   forbidClick: true
        // })
      }
      return Promise.reject(error.response)
    }
  }
)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log(err);
    })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios({ url, method: "post", data: params })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.log(err);

        reject(err)

      })
  })
}

export function put (url, params) {
  return new Promise((resolve, reject) => {
    axios.put(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}


