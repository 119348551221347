/*
包含n个mutation的type名称常量
 */
export const SETUSERINFO = 'setUserInfo' // 用户登录
export const MENUITEM = 'menuitem' // 折叠侧边栏
export const ASYNCROUTER = 'asyncRouter' // 异步路由
export const MESSAGELIST = 'messagelist' // 异步路由

// export const MOBILE_TENEMENT_ID = 'mobile_tenement_id' // 用户手机号和租户id





