import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
// import './utils/common.js'

import './api/ajax'
import './utils/element.js'
import { Debounce } from './utils/index.js'
import publicInfoDrawer from '@/components/publicInfoDrawer/publicInfoDrawer.vue'
import publicReply from '@/components/publicReply/publicReply.vue'
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
Vue.component("publicInfoDrawer", publicInfoDrawer);//全局自定义组件
Vue.component("publicReply", publicReply);//全局自定义组件
Vue.prototype.$baseUrl = process.env.VUE_APP_API_URL
Vue.prototype.$Debounce = Debounce


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
